<script>
import BoxWrapper from '@/components/global/BoxWrapper.vue'
import api from '@/mixins/api'
export default {
    name: 'MarketNoItemsModal',
    components: {
        BoxWrapper,
    },
    props: {
        language: {
            type: String,
            required: true,
        },
        unit: {
            type: String,
            required: true,
        },
    },
    methods: {
        logoutUser() {
            api.twoavy.logoutFromUnit(this.unit)
        },
    },
}
</script>

<template>
    <div
        class="no-items-modal position-absolute d-flex justify-content-center align-items-center"
        :class="'modal-' + unit"
    >
        <box-wrapper
            class="position-fixed d-flex flex-column"
            :title="$t('headline_no_items_available', language)"
        >
            <template #content>
                <div class="d-flex flex-column p-3">
                    <p
                        v-html="$t('description_no_items_available', language)"
                    />
                </div>
            </template>
            <template #bottom-button>
                <div
                    class="fowi-btn fowi-btn-primary fowi-btn-h48"
                    @click="logoutUser"
                >
                    {{ $t('app_logout_from_table') }}
                </div>
            </template>
        </box-wrapper>
    </div>
</template>

<style scoped lang="scss">
.no-items-modal {
    pointer-events: all;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.modal-market {
    &_0 {
        transform: rotate(180deg);
        bottom: 50%;
        right: 50%;
    }
    &_1 {
        transform: rotate(180deg);
        bottom: 50%;
        left: 50%;
    }
    &_2 {
        top: 50%;
        right: 50%;
    }
    &_3 {
        top: 50%;
        left: 50%;
    }
}
</style>
