<template>
    <div class="score-wrapper">
        <div class="top-flex">
            <div>
                <p>Position</p>
                <p>Score</p>
                <p>Erreicht am</p>
            </div>
            <div
                v-for="(score, i) in $store.getters['market/getHighscore'](
                    index,
                )"
                :key="i"
                class="table-content"
                :style="{
                    border: score && score.self === true && '2px solid white',
                    marginBottom:
                        i === 2 &&
                        $store.getters['market/getHighscore'](index).length ===
                            4 &&
                        '1ch',
                }"
            >
                <p v-if="score && score.position">{{ score.position }}</p>
                <p v-if="score && score.score">
                    {{
                        score.score % 1 === 0
                            ? score.score
                            : score.score.toFixed(2)
                    }}
                </p>
                <p v-if="score && score.entered_at">
                    {{
                        new Date(score.entered_at).toLocaleDateString('de-DE', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                        })
                    }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ScoreTable',
    props: {
        index: {
            type: Number,
            required: true,
        },
    },
}
</script>

<style lang="scss" scoped>
.score-wrapper {
    width: 20vw;
    height: 20vh;
    .top-flex {
        display: flex;
        flex-direction: column;
        .table-content {
            font-size: 1.7rem;
        }
        div {
            padding: 0 1vh;
            display: grid;
            grid-template-columns: 40% 30% 30%;
            grid-template-rows: auto;
            p:nth-child(3) {
                justify-content: end;
            }
            p:nth-child(2) {
                justify-content: center;
            }
            p {
                display: inline-flex;
                width: 100%;
                height: 100%;
                align-items: center;
            }
        }
    }
}
</style>
