<template>
    <div id="market" class="fill-parent">
        <market-game></market-game>
    </div>
</template>

<script>
import MarketGame from './MarketGame.vue'
export default {
    name: 'MarketMain',
    components: { MarketGame },
    data() {
        return {}
    },
    mounted() {
        this.$store.dispatch('market/resetState')
    },
    methods: {},
}
</script>

<style lang="scss" scoped></style>
