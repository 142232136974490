<template>
    <div class="card" @touchstart.prevent.stop="$emit('touchStart', $event)">
        <div v-if="Object.keys($route.query).includes('debug')" class="debug">
            {{ item.uuid.substring(0, 3) }}
        </div>
        <div class="image-container">
            <img
                ref="goettingenBild"
                :src="api.goettingen.getThumbnailUrl(item.goettItem, 400, 400)"
                :class="style"
                @load="setStyle()"
            />
        </div>
        <div class="details" :class="{ hidden: !showDetails }">
            <p>
                {{ getHeader }}
            </p>
            <p>
                {{ getValue }}
            </p>
        </div>
    </div>
</template>
<script>
import api, { FIELDS } from '../../../mixins/api'
import { getMaxLengthInMm } from '../../../utils/items'
import { CATEGORIES } from '../../../store/stations/3.009_market/market'
export default {
    name: 'MarketCard',
    components: {},
    props: {
        item: {
            type: Object,
            required: true,
        },
        showDetails: {
            type: Boolean,
            default: () => {
                return false
            },
        },
    },
    emits: ['touchStart', 'touchMove', 'touchEnd', 'supertouch'],
    data() {
        return {
            CATEGORIES,
            FIELDS,
            api,
            getMaxLengthInMm,
            style: '',
            category: null,
        }
    },
    computed: {
        getHeader() {
            let header
            if (
                this.userId !== null &&
                this.category !== null &&
                this.userLanguage !== null &&
                this.category !== undefined
            ) {
                header = this.$t(
                    `market_label_${this.category}`,
                    this.userLanguage,
                )
            } else {
                header = 'Card does not belong to User'
            }
            return header
        },
        getValue() {
            switch (this.category) {
                case CATEGORIES.BEAUTY:
                    return this.$store.getters['market/getRanking'](
                        this.item,
                        CATEGORIES.BEAUTY,
                    )
                        ? this.$store.getters['market/getRanking'](
                              this.item,
                              CATEGORIES.BEAUTY,
                          )
                        : 'n/a'
                case CATEGORIES.MONETARYVALUE:
                    return this.$store.getters['market/getRanking'](
                        this.item,
                        CATEGORIES.MONETARYVALUE,
                    )
                        ? this.$store.getters['market/getRanking'](
                              this.item,
                              CATEGORIES.MONETARYVALUE,
                          )
                        : 'n/a'
                case CATEGORIES.SIZE:
                    return (
                        (getMaxLengthInMm(this.item.goettItem) / 1000).toFixed(
                            2,
                        ) + 'm'
                    )
                case CATEGORIES.YEAR:
                    return this.item.goettItem[FIELDS.YEAR]
                        ? this.item.goettItem[FIELDS.YEAR][0]
                        : 'n/a'
                case CATEGORIES.SIGNIFICANCE:
                    return this.$store.getters['market/getRanking'](
                        this.item,
                        CATEGORIES.SIGNIFICANCE,
                    )
                        ? this.$store.getters['market/getRanking'](
                              this.item,
                              CATEGORIES.SIGNIFICANCE,
                          )
                        : 'n/a'
                default:
                    return 'n/a'
            }
        },
    },
    mounted() {
        this.getCategory()
    },
    updated() {
        this.getCategory()
    },
    methods: {
        setStyle() {
            if (
                this.$refs.goettingenBild.naturalWidth >=
                this.$refs.goettingenBild.naturalHeight
            ) {
                this.style = 'horizontal'
            } else {
                this.style = 'vertical'
            }
        },
        getCategory() {
            const userId = this.$store.getters['market/getUserIndexByItem'](
                this.item,
            )
            this.userLanguage = this.$store.state.market.userLanguages[userId]
            this.category = this.$store.state.market.categories[userId]
        },
    },
}
</script>
<style scoped lang="scss">
.card {
    pointer-events: all;
    $cardWidth: var(--marketCardWidth);
    $cardHeight: var(--marketCardHeight);
    width: $cardWidth;
    height: $cardHeight;
    border-radius: calc(15vw * var(--pxToVw));
    * {
        pointer-events: none;
    }
    overflow: hidden;
    .image-container {
        height: 50%;
        width: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        img {
            object-fit: cover;
        }
        .horizontal {
            height: auto;
            width: 100%;
            background-color: #0a7502;
        }

        .vertical {
            width: auto;
            height: 100%;
            left: 50%;
            background-color: #0a7502;
        }
    }
    .details {
        height: 50%;
        background-color: lightgray;
        color: black;
        font-size: 12px;
        padding: 10px;
        &.hidden {
            filter: blur(20px);
        }
        p:nth-child(2) {
            font-size: x-large;
            font-weight: bold;
        }
        p:nth-child(1) {
            font-size: large;
            font-weight: normal;
        }
        p {
            color: black;
            display: inline-flex;
            width: 100%;
            height: auto;
            justify-content: center;
        }
    }
    border: 1px solid black;

    .debug {
        position: absolute;
        top: 0;
        left: 0;
        background-color: lightsalmon;
        font-size: 32px;
        padding: 20px;
    }
}
</style>
