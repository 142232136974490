<template>
    <div
        class="fill-parent wrapper"
        :class="{ right: index === 0 || index === 3 }"
    >
        <div class="texts">
            <!-- {{ $i18n.locale }} -->
            <div
                v-if="
                    isStep(STEPS.IDLE) &&
                        $store.getters['interfaceTexts/getInterfaceText'](
                            'market_idle_greeting',
                        )
                "
            >
                <p>
                    <!-- {{ $t('market_idle_greeting') }} -->
                    {{
                        $store.getters['interfaceTexts/getInterfaceText'](
                            'market_idle_greeting',
                        ).de
                    }}
                    <br />
                    <span class="en">
                        {{
                            $store.getters['interfaceTexts/getInterfaceText'](
                                'market_idle_greeting',
                            ).en
                        }}
                    </span>
                </p>
                <p class="big">
                    <!-- {{ $t('market_idle_instruction') }} -->
                    {{
                        $store.getters['interfaceTexts/getInterfaceText'](
                            'market_idle_instruction',
                        ).de
                    }}
                    <br />
                    <span class="en">
                        {{
                            $store.getters['interfaceTexts/getInterfaceText'](
                                'market_idle_instruction',
                            ).en
                        }}
                    </span>
                </p>
            </div>
            <div v-if="isStep(STEPS.INTRODUCTION)">
                <p>
                    {{ $t(`market_introduction_goal`, userLanguage) }}
                </p>
                <p class="big">
                    {{
                        $t(
                            `market_introduction_collect_${$store.getters[
                                'market/getCategory'
                            ](index)}`,
                            userLanguage,
                        )
                    }}
                </p>
            </div>
            <div v-if="$store.state.market.steps[index] === STEPS.SELECTION">
                <p>
                    {{
                        $t(
                            `market_introduction_collect_${$store.getters[
                                'market/getCategory'
                            ](index)}`,
                            userLanguage,
                        )
                    }}
                </p>
                <p>
                    {{ $t('market_instruction_pick_five', userLanguage) }}
                </p>
                <p>
                    <span class="big">
                        {{
                            $store.getters['market/getSelectedItemsCount'](
                                index,
                            )
                        }}/5
                    </span>
                    {{ $t('selected_items', userLanguage) }}
                </p>
            </div>

            <div v-if="isStep(STEPS.SORT)">
                <p>
                    <span class="big">
                        {{
                            $t(
                                `market_sort_headline_${$store.getters[
                                    'market/getCategory'
                                ](index)}`,
                                userLanguage,
                            )
                        }}
                    </span>
                </p>
            </div>

            <div v-if="isStep(STEPS.EXCHANGE)">
                <p class="big">
                    {{ $t('market_instruction_exchange', userLanguage) }}
                </p>
                <div class="score-to-bottom">
                    <p
                        class="inline-flex-lower-text"
                        v-html="
                            $t(
                                `market_exchange_feedback_${$store.getters[
                                    'market/getCategory'
                                ](index)}`,
                                userLanguage,
                                {
                                    value: getHighscore(),
                                },
                            )
                        "
                    ></p>
                </div>
            </div>
            <div v-if="isStep(STEPS.CONCLUSION)">
                <p class="big">
                    {{
                        $t(
                            `market_feedback_${$store.getters[
                                'market/getCategory'
                            ](index)}`,
                            userLanguage,
                        )
                    }}
                </p>
                <div class="score-to-bottom">
                    <p
                        class="inline-flex-lower-text"
                        v-html="
                            $t(
                                `market_exchange_feedback_${$store.getters[
                                    'market/getCategory'
                                ](index)}`,
                                userLanguage,
                                {
                                    value: getHighscore(),
                                },
                            )
                        "
                    ></p>
                </div>
            </div>
            <div v-if="isStep(STEPS.HIGHSCORE)">
                <ScoreTable :index="index" />
            </div>
            <div v-if="isStep(STEPS.FINISH)">
                <p class="big">
                    {{ $t('market_farewell', userLanguage) }}
                </p>
                <p>
                    {{ $t('market_instruction_finish', userLanguage) }}
                </p>
            </div>
            <div v-if="isStep(STEPS.OUTRODISCARDED)">
                <p class="big">
                    {{
                        $t('market_goodbye_collection_not_saved', userLanguage)
                    }}
                </p>
            </div>
            <div v-if="isStep(STEPS.OUTROSAVED)">
                <p class="big">
                    {{ $t('market_goodbye_collection_saved', userLanguage) }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import api from '../../../mixins/api'
import { getMessages } from '../../../i18n'
import { STEPS } from '../../../store/stations/3.009_market/market'
import ScoreTable from '@/components/stations/3.009_market/MarketScoreTable.vue'

export default {
    name: 'User',
    components: { ScoreTable },
    props: {
        index: {
            type: [Number, String],
            required: true,
        },
        userLanguage: {
            type: [String],
            required: true,
        },
    },
    data() {
        return { api, STEPS }
    },
    watch: {
        '$store.state.users': {
            handler(value) {
                Object.entries(value).forEach(entry => {
                    if (entry[0] === `market_${this.index}`) {
                        this.$i18n.locale = entry[1].settings.language
                    }
                })
            },
            deep: true,
        },
    },
    mounted() {
        getMessages().then(messages => {
            Object.keys(messages).forEach(key => {
                this.$i18n.setLocaleMessage(key, messages[key])
            })
        })
    },
    methods: {
        completeIntroduction() {
            this.$store.dispatch('market/setNextStep', this.index)
        },
        isStep(step) {
            return this.$store.state.market.steps[this.index] === step
        },
        hasHighScore() {
            const highScoreSessionUuid = this.$store.getters[
                'market/getHighscore'
            ](this.index)?.uuid
            const sessionUuid = this.$store.getters['market/getSession'](
                this.index,
            )
            return highScoreSessionUuid === sessionUuid
        },
        getHighscore() {
            return `<span style="text-decoration: underline white">${
                this.$store.getters['market/getScore'](this.index) % 1 === 0
                    ? this.$store.getters['market/getScore'](this.index)
                    : this.$store.getters['market/getScore'](
                          this.index,
                      ).toFixed(2)
            }</span>`
        },
    },
}
</script>

<style scoped lang="scss">
.wrapper {
    position: relative;
    width: 890px;
    transform: translateX(calc(-50% + 760px));
}

.score-to-bottom {
    display: flex;
    align-items: flex-end;
    .inline-flex-lower-text {
        display: inline-block;
    }
}

.idle,
.texts {
    position: absolute;
    font-size: 36px;
    margin-top: 128px;
    height: 550px;
    overflow: hidden;
    .big {
        font-size: 40px; // 48px
    }
    .en {
        font-size: 0.8em;
        color: var(--semilightgrey);
    }
}
</style>
