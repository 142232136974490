var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fill-parent wrapper",class:{ right: _vm.index === 0 || _vm.index === 3 }},[_c('div',{staticClass:"texts"},[(
                _vm.isStep(_vm.STEPS.IDLE) &&
                    _vm.$store.getters['interfaceTexts/getInterfaceText'](
                        'market_idle_greeting',
                    )
            )?_c('div',[_c('p',[_vm._v(" "+_vm._s(_vm.$store.getters['interfaceTexts/getInterfaceText']( 'market_idle_greeting', ).de)+" "),_c('br'),_c('span',{staticClass:"en"},[_vm._v(" "+_vm._s(_vm.$store.getters['interfaceTexts/getInterfaceText']( 'market_idle_greeting', ).en)+" ")])]),_c('p',{staticClass:"big"},[_vm._v(" "+_vm._s(_vm.$store.getters['interfaceTexts/getInterfaceText']( 'market_idle_instruction', ).de)+" "),_c('br'),_c('span',{staticClass:"en"},[_vm._v(" "+_vm._s(_vm.$store.getters['interfaceTexts/getInterfaceText']( 'market_idle_instruction', ).en)+" ")])])]):_vm._e(),(_vm.isStep(_vm.STEPS.INTRODUCTION))?_c('div',[_c('p',[_vm._v(" "+_vm._s(_vm.$t(`market_introduction_goal`, _vm.userLanguage))+" ")]),_c('p',{staticClass:"big"},[_vm._v(" "+_vm._s(_vm.$t( `market_introduction_collect_${_vm.$store.getters[ 'market/getCategory' ](_vm.index)}`, _vm.userLanguage, ))+" ")])]):_vm._e(),(_vm.$store.state.market.steps[_vm.index] === _vm.STEPS.SELECTION)?_c('div',[_c('p',[_vm._v(" "+_vm._s(_vm.$t( `market_introduction_collect_${_vm.$store.getters[ 'market/getCategory' ](_vm.index)}`, _vm.userLanguage, ))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('market_instruction_pick_five', _vm.userLanguage))+" ")]),_c('p',[_c('span',{staticClass:"big"},[_vm._v(" "+_vm._s(_vm.$store.getters['market/getSelectedItemsCount']( _vm.index, ))+"/5 ")]),_vm._v(" "+_vm._s(_vm.$t('selected_items', _vm.userLanguage))+" ")])]):_vm._e(),(_vm.isStep(_vm.STEPS.SORT))?_c('div',[_c('p',[_c('span',{staticClass:"big"},[_vm._v(" "+_vm._s(_vm.$t( `market_sort_headline_${_vm.$store.getters[ 'market/getCategory' ](_vm.index)}`, _vm.userLanguage, ))+" ")])])]):_vm._e(),(_vm.isStep(_vm.STEPS.EXCHANGE))?_c('div',[_c('p',{staticClass:"big"},[_vm._v(" "+_vm._s(_vm.$t('market_instruction_exchange', _vm.userLanguage))+" ")]),_c('div',{staticClass:"score-to-bottom"},[_c('p',{staticClass:"inline-flex-lower-text",domProps:{"innerHTML":_vm._s(
                        _vm.$t(
                            `market_exchange_feedback_${_vm.$store.getters[
                                'market/getCategory'
                            ](_vm.index)}`,
                            _vm.userLanguage,
                            {
                                value: _vm.getHighscore(),
                            },
                        )
                    )}})])]):_vm._e(),(_vm.isStep(_vm.STEPS.CONCLUSION))?_c('div',[_c('p',{staticClass:"big"},[_vm._v(" "+_vm._s(_vm.$t( `market_feedback_${_vm.$store.getters[ 'market/getCategory' ](_vm.index)}`, _vm.userLanguage, ))+" ")]),_c('div',{staticClass:"score-to-bottom"},[_c('p',{staticClass:"inline-flex-lower-text",domProps:{"innerHTML":_vm._s(
                        _vm.$t(
                            `market_exchange_feedback_${_vm.$store.getters[
                                'market/getCategory'
                            ](_vm.index)}`,
                            _vm.userLanguage,
                            {
                                value: _vm.getHighscore(),
                            },
                        )
                    )}})])]):_vm._e(),(_vm.isStep(_vm.STEPS.HIGHSCORE))?_c('div',[_c('ScoreTable',{attrs:{"index":_vm.index}})],1):_vm._e(),(_vm.isStep(_vm.STEPS.FINISH))?_c('div',[_c('p',{staticClass:"big"},[_vm._v(" "+_vm._s(_vm.$t('market_farewell', _vm.userLanguage))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('market_instruction_finish', _vm.userLanguage))+" ")])]):_vm._e(),(_vm.isStep(_vm.STEPS.OUTRODISCARDED))?_c('div',[_c('p',{staticClass:"big"},[_vm._v(" "+_vm._s(_vm.$t('market_goodbye_collection_not_saved', _vm.userLanguage))+" ")])]):_vm._e(),(_vm.isStep(_vm.STEPS.OUTROSAVED))?_c('div',[_c('p',{staticClass:"big"},[_vm._v(" "+_vm._s(_vm.$t('market_goodbye_collection_saved', _vm.userLanguage))+" ")])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }