<template>
    <div class="axis" :class="show ? 'opacity-full' : 'opacity-none'">
        <div class="minLabel">
            {{
                $t(
                    `market_sort_minLabel_${$store.getters[
                        'market/getCategory'
                    ](userIndex)}`,
                    userLanguage,
                )
            }}
        </div>
        <div class="maxLabel">
            {{
                $t(
                    `market_sort_maxLabel_${$store.getters[
                        'market/getCategory'
                    ](userIndex)}`,
                    userLanguage,
                )
            }}
        </div>
    </div>
</template>
<script>
export default {
    name: 'Axis',
    components: {},
    props: {
        userIndex: {
            required: true,
            type: [Number, String],
        },
        userLanguage: {
            type: [String],
            required: true,
        },
        show: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {}
    },
    methods: {},
}
</script>
<style scoped lang="scss">
.opacity-full {
    opacity: 1;
}

.opacity-none {
    opacity: 0;
}

.axis {
    border-top: 3px solid var(--white);
    display: flex;
    flex-direction: row;
    padding-top: 10px;
    .minLabel {
        flex-grow: 1;
        /* padding-left: 10px; */
    }
    .maxLabel {
        /* padding-right: 10px; */
    }
}
</style>
