import { Engine, Body, Bodies, Runner, Events, World } from 'matter-js'
import { randomNumber, getDistance } from './math'
import { cloneDeep } from 'lodash'

const defaultOptions = {
    center: { x: 0.5, y: 0.5 },
    spread: 0.5,
    // finishCallback: () => {
    //     console.log('unload animation finished')
    // },
}
const Animation = class Animation {
    constructor(element, callback, options = {}) {
        this.options = {
            ...defaultOptions,
            ...options,
        }
        this.engine = Engine.create()
        this.engine.gravity.x = 0
        this.engine.gravity.y = 0
        this.runner = Runner.create()
        this.bodies = {}
        this.positions = {}
        const rect = element.getBoundingClientRect()
        this.rect = rect
        this.callback = callback

        const wallWidth = 4
        // ground
        World.add(
            this.engine.world,
            Bodies.rectangle(
                rect.width / 2,
                rect.height - 1,
                rect.width,
                wallWidth,
                {
                    isStatic: true,
                },
            ),
        )

        // ceiling
        World.add(
            this.engine.world,
            Bodies.rectangle(rect.width / 2, 0, rect.width, wallWidth, {
                isStatic: true,
            }),
        )
        // left
        World.add(
            this.engine.world,
            Bodies.rectangle(0, rect.height / 2, wallWidth, rect.height, {
                isStatic: true,
            }),
        )
        // right
        World.add(
            this.engine.world,
            Bodies.rectangle(
                rect.width,
                rect.height / 2,
                wallWidth,
                rect.height,
                {
                    isStatic: true,
                },
            ),
        )
        Events.on(this.runner, 'afterUpdate', event => {
            if (event.timestamp > 6000) {
                // in case stable detection fails
                this.stop()
            }
            const positions = {}
            let stable = true
            Object.entries(this.bodies).forEach(entry => {
                const PI = entry[0]
                const position = { ...entry[1].position }
                const oldPosition = this.positions[PI]
                if (oldPosition) {
                    if (getDistance(position, oldPosition) > 0.5) {
                        stable = false
                    }
                } else {
                    stable = false
                }
                positions[PI] = position
            })
            if (stable) {
                // this.options.finishCallback()
                this.stop()
            }
            // TODO: check if stable, then stop/trigger finished callbac
            this.positions = positions
            this.callback(cloneDeep(this.positions))
        })
    }
    addBody(id, pos, force = { x: 0, y: 0 }, radius = 50) {
        if (!pos) {
            pos = {}
            pos.x =
                this.rect.width * this.options.center.x +
                randomNumber(
                    (-this.options.spread * this.rect.width) / 2,
                    (this.options.spread * this.rect.width) / 2,
                )
            pos.y =
                this.rect.height * this.options.center.y +
                randomNumber(
                    (-this.options.spread * this.rect.height) / 2,
                    (this.options.spread * this.rect.height) / 2,
                )
        }
        const body = Bodies.circle(pos.x, pos.y, radius, {
            density: 0.2,
            frictionAir: 0.05,
            restitution: 0.9,
            friction: 0.05,
            mass: 0.0001,
        })
        // Body.applyForce(body, body.position, { x: 0.0000001, y: 0 })
        Body.applyForce(body, body.position, force)
        this.bodies[id] = body
        World.add(this.engine.world, [body])
    }
    addBodyWithForce(id, pos, force = { x: 0.0000001, y: 0 }, radius = 50) {
        this.addBody(id, pos, force, radius)
    }
    start() {
        Runner.run(this.runner, this.engine)
    }
    stop() {
        Runner.stop(this.runner)
    }
}

export default Animation
