var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"container",staticClass:"marketGame fill-parent",class:{ debug: _vm.debug }},[_vm._m(0),_vm._l((_vm.NUMBEROFUSERS),function(userIndex){return _c('div',{key:`user-area-${userIndex - 1}`,ref:`user-area-${userIndex - 1}`,refInFor:true,staticClass:"position-absolute user-area-size",class:`user-area-${userIndex - 1}`})}),(_vm.ready && Object.keys(_vm.getActiveUsers).length > 0)?_vm._l((_vm.getActiveUsers),function(userIndex,name){return _c('login-timer',{key:`login-timer-${userIndex.uuid}`,attrs:{"unit":`${_vm.$route.name}_${name}`,"user-uuid":userIndex.uuid,"active-area":_vm.$refs[`user-area-${name}`][0]}})}):_vm._e(),_c('div',{staticClass:"texts fill-parent pointer-events-none"},_vm._l((_vm.NUMBEROFUSERS),function(userIndex){return _c('span',{key:`market-user-${userIndex - 1}`},[(
                    !_vm.userLoggedIn(userIndex - 1) ||
                        _vm.userItemsCount(userIndex - 1) >= 5
                )?_c('texts',{ref:`texts-${userIndex - 1}`,refInFor:true,class:`market-user market-user${userIndex - 1}`,attrs:{"index":userIndex - 1,"user-language":_vm.userLanguage(userIndex - 1)}}):_c('market-no-items-modal',{attrs:{"unit":_vm.getUnitKey(userIndex - 1),"language":_vm.userLanguage(userIndex - 1)}})],1)}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasLoaded),expression:"hasLoaded"}],staticClass:"cardsContainer fill-parent pointer-events-none"},[_vm._l((_vm.NUMBEROFUSERS),function(index){return _c('div',{key:`placeholdersContainer-${index - 1}`,ref:`placeholdersContainer_${index - 1}`,refInFor:true,class:`placeholdersContainer placeholdersContainer-${index - 1}`,style:(_vm.getPlaceholdersContainerStyle(index - 1))},[_c('div',{staticClass:"placeholders"},_vm._l((_vm.NUMBEROFSLOTS),function(i){return _c('div',{key:'placeholder_' + i,ref:`placeholder_${index - 1}-${i - 1}`,refInFor:true,class:`placeholder placeholder_${index - 1}-${i - 1}`})}),0),_c('axis',{key:`axis-${index - 1}`,class:`axis axis-${index - 1}`,attrs:{"user-index":index - 1,"user-language":_vm.userLanguage(index - 1),"show":_vm.$store.state.market.steps[index - 1] === _vm.STEPS.IDLE ||
                        _vm.$store.state.market.steps[index - 1] ===
                            _vm.STEPS.INTRODUCTION ||
                        _vm.$store.state.market.steps[index - 1] ===
                            _vm.STEPS.SELECTION ||
                        _vm.$store.state.market.steps[index - 1] === _vm.STEPS.SORT}})],1)}),_vm._l((_vm.$store.getters['market/getAllItems']),function(item){return _c('market-card',{key:`card-${item.uuid}`,class:`card card-${item.uuid}`,attrs:{"data-uuid":item.uuid,"item":item,"show-details":_vm.areDetailsShown(item)}})}),_vm._l((_vm.NUMBEROFUSERS),function(index){return [(_vm.areItemsShown(index - 1))?_c('items',{key:`items-${index - 1}`,class:`item items-${index - 1}`,attrs:{"index":index - 1}}):_vm._e()]}),_vm._l((_vm.NUMBEROFUSERS),function(userIndex){return [_c('div',{key:`buttons-${userIndex - 1}`,class:`buttons buttons-${userIndex - 1}`,style:(_vm.getButtonsStyle(userIndex - 1))},[(
                        (_vm.isStep(userIndex - 1, _vm.STEPS.SORT) &&
                            _vm.$store.getters['market/getUserItemsCount'](
                                userIndex - 1,
                            ) === _vm.NUMBEROFSLOTS) ||
                            _vm.isStep(userIndex - 1, _vm.STEPS.EXCHANGE) ||
                            _vm.isStep(userIndex - 1, _vm.STEPS.CONCLUSION) ||
                            _vm.isStep(userIndex - 1, _vm.STEPS.HIGHSCORE)
                    )?_c('div',{staticClass:"button clickable",on:{"click":function($event){return _vm.onCheckIconClicked(userIndex - 1)}}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'check']}}),_c('p',[_vm._v(_vm._s(_vm.getButtonTexts(userIndex - 1)))])],1):_vm._e(),(_vm.isStep(userIndex - 1, _vm.STEPS.FINISH))?[_c('div',{staticClass:"button clickable",on:{"click":function($event){return _vm.discardCollection(userIndex - 1)}}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'trash']}}),_c('p',[_vm._v(" "+_vm._s(_vm.$t( 'market-discard-collection', _vm.userLanguage(userIndex), ))+" ")])],1),_c('div',{staticClass:"button clickable",on:{"click":function($event){return _vm.saveCollection(userIndex - 1)}}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'cloud-upload']}}),_c('p',[_vm._v(" "+_vm._s(_vm.$t( 'market-save-collection', _vm.userLanguage(userIndex), ))+" ")])],1)]:_vm._e()],2)]})],2)],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"debugGrid pointer-events-none"},[_c('div',{staticClass:"left"}),_c('div',{staticClass:"center"}),_c('div',{staticClass:"right"}),_c('div',{staticClass:"horizontal"})])
}]

export { render, staticRenderFns }