var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"score-wrapper"},[_c('div',{staticClass:"top-flex"},[_vm._m(0),_vm._l((_vm.$store.getters['market/getHighscore'](
                _vm.index,
            )),function(score,i){return _c('div',{key:i,staticClass:"table-content",style:({
                border: score && score.self === true && '2px solid white',
                marginBottom:
                    i === 2 &&
                    _vm.$store.getters['market/getHighscore'](_vm.index).length ===
                        4 &&
                    '1ch',
            })},[(score && score.position)?_c('p',[_vm._v(_vm._s(score.position))]):_vm._e(),(score && score.score)?_c('p',[_vm._v(" "+_vm._s(score.score % 1 === 0 ? score.score : score.score.toFixed(2))+" ")]):_vm._e(),(score && score.entered_at)?_c('p',[_vm._v(" "+_vm._s(new Date(score.entered_at).toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit', }))+" ")]):_vm._e()])})],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v("Position")]),_c('p',[_vm._v("Score")]),_c('p',[_vm._v("Erreicht am")])])
}]

export { render, staticRenderFns }